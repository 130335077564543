import React from "react"
import { graphql } from "gatsby"
import { injectIntl, Link } from "gatsby-plugin-react-intl"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import HeaderColor from "../components/HeaderColor"
import HeaderCover from "../components/HeaderCover"
import Footer from "../components/Footer"
import { Row, Col, Empty } from "antd"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import styled from "@emotion/styled"
//import { ButtonTransparent } from "../components/Button"

const RowEmptyWrapper = styled(Row)`
  margin: 120px 0;
`
const QueryWrapper = styled.div`
  margin: 80px 0;

  @media only screen and (min-width: 768px) {
    margin: 100px 0;
  }
`
const CardWrapper = styled.div`
  position: relative;
  background: #fff;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  height: 100%;
  min-height: 100%;
  transition: all 3s ease-out;
  &:hover {
    box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.2);
    .contentWrapper {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateY(-10%);
      border-radius: 10px;
      width: 100%;
      .excerpt {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .buttomexcerpt {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  .featuredWrapper {
    display: flex;
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: relative;
    z-index: 1;
    a {
      display: inline-flex;
    }
    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
  .contentWrapper {
    padding: 35px 25px 25px 25px;
    font-family: "Kanit", sans-serif;
    background: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
    transition: all 0.05s ease-in;
    z-index: 2;
    .cat {
      font-weight: 700;
      font-size: 16px;
      color: #7497B2;
      margin-bottom: 10px;
    }
    .future {
      font-weight: 400;
      font-size: 18px;
      color: #7497B2;
      margin-bottom: 0;
    }
    h3 {
      font-weight: 400;
      font-size: 24px;
      color: #181818;
      margin-bottom: 0px;
    }
    .excerpt {
      display: none;
      font-weight: 400;
      font-size: 16px;
      color: #646464;
    }
    .buttomexcerpt {
      display: none;
      padding: 10px 63px;
      font-family: "Kanit", sans-serif;
      font-weight: 400;
      font-size: 18px;
      color: #7497b2;
      background-color: transparent;
      border: 1px solid #7497b2;
      border-radius: 5px;
      text-decoration: none;
      transition: all 0.25s ease-in-out;
      width: 240px;
      &:hover {
        color: #fff;
        background-color: #7497b2;
        text-decoration: none;
      }
    }
  }
`
const PaginateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
`

const ProductsPage = ({ data, pageContext, intl }) => {
  const locale = intl && intl.locale ? intl.locale : `th`
  const { pageFields, productsPosts } = data
  const { seoTitle, seoDescription, coverImage } = pageFields.edges[0].node
  const getCoverImage = getImage(coverImage)

  const posts = productsPosts.edges
  const { currentPage, numProducts } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numProducts
  let prevPage = `/products/` + (currentPage - 1) + `/`
  if (Number(currentPage) < 3) {
    prevPage = `/products/`
  }
  const nextPage = `/products/` + (currentPage + 1) + `/`

  return (
    <Layout>
      <Seo lang={locale} title={seoTitle} description={seoDescription} />
      <HeaderColor />
      <HeaderCover
        bgImage={getCoverImage}
        coverText={intl.formatMessage({ id: "navMenu5" })}
      />
      {data ? (
        <QueryWrapper>
          <div className="gb-container">
            <Row gutter={[16, 16]}>
              {posts &&
                posts.map((post, index) => {
                  const getFeatured = getImage(post.node.featured)
                  const future = post.node.futureProduct
                  let linkpost
                  if (future === "(Future Product)") {
                    linkpost = (
                      <Link to={`/products/#/`}>
                        <GatsbyImage image={getFeatured} alt="" />
                      </Link>
                    )
                  } else {
                    linkpost = (
                      <Link to={`/products/${post.node.slug}/`}>
                        <GatsbyImage image={getFeatured} alt="" />
                      </Link>
                    )
                  }
                  return (
                    <Col key={index} xs={24} sm={12} lg={8}>
                      <CardWrapper>
                        <div className="featuredWrapper">{linkpost}</div>
                        <div className="contentWrapper">
                          <p className="cat">
                            {intl.formatMessage({ id: "navMenu5" })}
                          </p>
                          {future === "(Future Product)" ? (
                            <Link to={`/products/#/`}>
                              <h3
                                dangerouslySetInnerHTML={{
                                  __html: post.node.title,
                                }}
                              />
                              <p className="future">{future}</p>
                              <p
                                className="excerpt"
                                dangerouslySetInnerHTML={{
                                  __html: post.node.excerpt.excerpt,
                                }}
                              />
                            </Link>
                          ) : (
                            <Link to={`/products/${post.node.slug}/`}>
                              <h3
                                dangerouslySetInnerHTML={{
                                  __html: post.node.title,
                                }}
                              />
                              <p className="future">{future}</p>
                              <p
                                className="excerpt"
                                dangerouslySetInnerHTML={{
                                  __html: post.node.excerpt.excerpt,
                                }}
                              />
                              <a
                                href={`/products/${post.node.slug}/`}
                                className="buttomexcerpt"
                              >
                                {intl.formatMessage({ id: "textReadDetails" })}
                              </a>
                            </Link>
                          )}
                        </div>
                      </CardWrapper>
                    </Col>
                  )
                })}
            </Row>
            {numProducts > 1 && (
              <Row>
                <Col span={24}>
                  <PaginateWrapper>
                    <ul className="ant-pagination">
                      {isFirst ? (
                        <li
                          key="prev"
                          className="ant-pagination-prev ant-pagination-disabled"
                        >
                          <button
                            className="ant-pagination-item-link"
                            type="button"
                            tabIndex="-1"
                            disabled
                          >
                            <LeftOutlined />
                          </button>
                        </li>
                      ) : (
                        <li key="prev" className="ant-pagination-item">
                          <Link to={prevPage} rel="prev">
                            <LeftOutlined />
                          </Link>
                        </li>
                      )}
                      {Array.from({ length: numProducts }, (_, i) => (
                        <li
                          key={`pagination-number${i + 1}`}
                          className={
                            i + 1 === currentPage
                              ? "ant-pagination-item ant-pagination-item-active"
                              : "ant-pagination-item"
                          }
                        >
                          <Link
                            to={`/products/${i < 1 ? "" : i + 1 + "/"}`}
                            className={i + 1 === currentPage ? `active` : ``}
                          >
                            {i + 1}
                          </Link>
                        </li>
                      ))}
                      {isLast ? (
                        <li
                          key="next"
                          className="ant-pagination-next ant-pagination-disabled"
                        >
                          <button
                            className="ant-pagination-item-link"
                            type="button"
                            tabIndex="-1"
                            disabled
                          >
                            <RightOutlined />
                          </button>
                        </li>
                      ) : (
                        <li key="next" className="ant-pagination-item">
                          <Link to={nextPage} rel="next">
                            <RightOutlined />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </PaginateWrapper>
                </Col>
              </Row>
            )}
          </div>
        </QueryWrapper>
      ) : (
        <RowEmptyWrapper>
          <Col span={24}>
            <Empty />
          </Col>
        </RowEmptyWrapper>
      )}
      <Footer />
    </Layout>
  )
}

export default injectIntl(ProductsPage)

export const query = graphql`
  query ProductsPostsQuery(
    $skip: Int!
    $limit: Int!
    $locale: String
    $language: String
  ) {
    pageFields: allContentfulProductsPage(
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          seoTitle
          seoDescription
          coverImage {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
    productsPosts: allContentfulProductsPosts(
      limit: $limit
      skip: $skip
      sort: { order: DESC, fields: [futureProduct, createdAt] }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          slug
          title
          futureProduct
          createdAt(formatString: "DD MMM YYYY", locale: $language)
          featured {
            gatsbyImageData(width: 510, height: 401, placeholder: BLURRED)
          }
          excerpt {
            excerpt
          }
        }
      }
    }
  }
`
